import ApiService from "./ApiService";

export default class manageTeamService {
  static getTeamMemberList = () => ApiService.get("/admin/get-team-member-list");
  static addTeamMember = (data) => ApiService.post("/admin/add-team-member", data);
  static deleteTeamMember = (data) => ApiService.put("/admin/delete-team-member", data);
  static getTeamMemberById = (teamMemberId) => ApiService.get(`/admin/team-member-info/${teamMemberId}`);
  static updateTeamMember = (updateData) => ApiService.post("/admin/update-team-member", updateData);
  static getTeamMemberType = () => ApiService.get("/admin/get-team-member-type");
}
