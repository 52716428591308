import ApiService from "./ApiService";

export default class OrderService {
    static getAllFolderCount = () => ApiService.get('admin/get-all-folder-count');
    static getOrdersList = ({ order }) => ApiService.get(`/admin/get-orders-list/${order.orderName}?page=${order.page}&limit=${order.perPage}&delay=1`);
    static getOrderDetailsByOrderId = (orderId) =>
        ApiService.get(`/admin/get-orders-details/${orderId}`);
    static getUploadDocDetails = (orderId) =>
        ApiService.get(`/admin/get-upload-doc-details/${orderId}`);
    static uploadVisaDocument = (data) =>
        // ApiService.upload("/admin/upload-visa", data);  // for multiple member
        ApiService.post("/admin/upload-visa-document", data);
    static resendMail = (data) => ApiService.post("/admin/resend-mail", data);
    static MoveOrderStatus = (data) =>
        ApiService.put(`/admin/move-order-status`, data);
    static deleteOrdersData = (deleteData) =>
        ApiService.put(`/admin/change-order-status`, deleteData);
    static searchOrder = (data) => ApiService.post(`/admin/search-order`, data);
    
  static GetDefencePackPdf = (data) =>
  ApiService.post("/admin/get-defence-pack-pdf", data, {
    responseType: "blob",
  });
    static defencePack = (orderId) =>ApiService.post("/admin/defence-pack-details", orderId);
    static sendRefundMail = (refundData) =>
    ApiService.put("/admin/move-to-complete-refund", refundData);
}