import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import OrderService from "../../services/OrderService";

export const getAllFolderCount = createAsyncThunk("/order/getAllFolderCount", async () => {
  try {
    const response = await OrderService.getAllFolderCount();
    const data = await response.data;
    console.log('data: ', data)
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

export const getPhonenumberList = createAsyncThunk("/order/getPhonenumberList", async () => {
  try {
    const response = await OrderService.getPhonenumberList();
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

export const getOrdersList = createAsyncThunk("/order/getOrdersList", async (order) => {
  try {
    const response = await OrderService.getOrdersList({ order });
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response;
  }
}
);

export const getOrderDetailsByOrderId = createAsyncThunk("/order/getOrderDetailsByOrderId", async (orderId) => {
  try {
    const response = await OrderService.getOrderDetailsByOrderId(orderId);
    const data = await response.data;
    // console.log('data: ', data);
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

export const getUploadDocDetails = createAsyncThunk(
  "/order/getUploadDocDetails",
  async (orderId) => {
    try {
      const response = await OrderService.getUploadDocDetails(orderId);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const deleteOrdersData = createAsyncThunk("/order/deleteOrdersData", async (data) => {
  try {
    await OrderService.deleteOrdersData(data);
    return data.orderId;
  } catch (error) {
    return error.response.data;
  }
}
);

export const sendRefundMail = createAsyncThunk("/admin/sendRefundMail", async (refundData) => {
  try {
    const response = await OrderService.sendRefundMail({
      orderId: refundData,
    });
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

// export const uploadVisaDocument = createAsyncThunk("/admin/upload-visa", async (uploadData) => {
export const uploadVisaDocument = createAsyncThunk("/admin/uploadVisaDocument", async (uploadData) => {
  try {
    const response = await OrderService.uploadVisaDocument(uploadData);
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

export const resendMail = createAsyncThunk("/admin/resend-mail", async (mailData) => {
  try {
    const response = await OrderService.resendMail(mailData);
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

export const ChangeOrderStatus = createAsyncThunk("/order/ChangeOrderStatus", async (orderStatus) => {
  try {
    const response = await OrderService.ChangeOrderStatus(orderStatus);
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

export const defencePack = createAsyncThunk("/admin/defencePack", async (defenceData) => {
  try {
    const response = await OrderService.defencePack(defenceData);
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

export const GetDefencePackPdf = createAsyncThunk("/admin/GetDefencePackPdf", async (defenceData) => {
  try {
    const response = await OrderService.GetDefencePackPdf(defenceData);
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

export const printOrderInfo = createAsyncThunk("/admin/printOrderInfo", async (orderId) => {
  try {
    const response = await OrderService.printOrderInfo(orderId);
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

export const viewProcessOrder = createAsyncThunk("/order/viewProcessOrder", async (tmId) => {
  try {
    const response = await OrderService.viewProcessOrder(tmId);
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

export const trackCustomerOrder = createAsyncThunk("/order/trackCustomerOrder", async (orderData) => {
  try {
    const response = await OrderService.trackCustomerOrder(orderData);
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

export const getDownloadHistory = createAsyncThunk("/order/getDownloadHistory", async (orderId) => {
  try {
    const response = await OrderService.getDownloadHistory(orderId);
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

export const searchOrder = createAsyncThunk("/order/searchOrder", async (searchData) => {
  try {
    const response = await OrderService.searchOrder(searchData);
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

export const getTwilioLog = createAsyncThunk("/order/getTwilioLog", async (TwilioData) => {
  try {
    const response = await OrderService.getTwilioLog(TwilioData);
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

export const getProcessingLog = createAsyncThunk("/order/getProcessingLog", async (Data) => {
  try {
    const response = await OrderService.getProcessingLog(Data);
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

export const encryptOpenSSL = createAsyncThunk("/order/encryptOpenSSL", async (Data) => {
  try {
    const response = await OrderService.encryptOpenSSL(Data);
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

export const getDefencePackPdf = createAsyncThunk("/order/getDefencePackPdf", async (Data) => {
  try {
    const response = await OrderService.getDefencePackPdf(Data);
    const data = await response.data;
    return data;
  } catch (error) {
    return error.response.data;
  }
}
);

export const MoveOrderStatus = createAsyncThunk(
  "/order/MoveOrderStatus",
  async (orderStatus) => {
    try {
      const response = await OrderService.MoveOrderStatus(orderStatus);
      const data = await response.data;
      return data;
    } catch (error) {
      return error.response.data;
    }
  }
);

export const orderSlice = createSlice({
  name: "order",
  initialState: {
    loading: false,
    error: null,
    mailCount: null,
    ordersList: [],
    orderDetails: [],
    uploadDocDetails: [],
    processOrdersList: [],
    orderInfo: {},
    phoneNumberList: [],
    showSideBar: true,
    selectedData: [],
    count: {},
    orderId: null,
    twilioLogList: [],
    twilioLogCount: [],
    processingLogList: [],
    processingLogCount: null,
    encryptedId: null,
  },
  reducers: {
    toggleSidebar: (state) => ({
      ...state,
      showSideBar: !state.showSideBar,
    }),
    toggleNavbar: (state) => ({
      ...state,
      showNavbar: !state.showNavbar,
    }),
    handleChange: (state, action) => {
      const allDeleteData = action.payload.selectedRows;
      const data = allDeleteData.map(({ id, status: status }) => ({
        id,
        status,
      }));
      // const newData = allDeleteData.map((item) => item.id);
      // const data = newData.map((item) =>
      //   item.length > 6 ? Number(item.slice(4, 10)) : Number(item)
      // );
      return {
        ...state,
        selectedData: data,
      };
    },
    cleanOrdersList: (state) => ({
      ordersList: [],
    }),
  },

  extraReducers: (builder) => {
    builder.addCase(getAllFolderCount.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAllFolderCount.fulfilled, (state, action) => {
      state.loading = false;
      state.count = action?.payload?.result;
    });
    builder.addCase(getAllFolderCount.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getPhonenumberList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPhonenumberList.fulfilled, (state, action) => {
      state.loading = false;
      state.phoneNumberList = action?.payload?.data;
      state.phoneNumberList = [
        ...state.phoneNumberList.map((x) => {
          return { Phone: x.phoneNumber3 };
        }),
      ];
    });
    builder.addCase(getPhonenumberList.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getOrdersList.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getOrdersList.fulfilled, (state, action) => {
      state.loading = false;
      state.ordersList = action?.payload?.data;
    });
    builder.addCase(getOrdersList.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    });
    builder.addCase(getOrderDetailsByOrderId.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getOrderDetailsByOrderId.fulfilled, (state, action) => {
      console.log("action?.payload?.data", action?.payload?.data);
      state.loading = false;
      state.orderDetails = action?.payload?.data;
    });
    builder.addCase(getOrderDetailsByOrderId.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(getUploadDocDetails.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getUploadDocDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.uploadDocDetails = action?.payload?.data;
    });
    builder.addCase(getUploadDocDetails.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(deleteOrdersData.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(deleteOrdersData.fulfilled, (state, action) => {
      state.loading = false;
      state.ordersList = state.ordersList.filter(
        (item) => action?.payload.indexOf(item.id) === -1
      );
    });
    builder.addCase(deleteOrdersData.rejected, (state, action) => {
      state.loading = false;
    });

    builder.addCase(viewProcessOrder.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(viewProcessOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.processOrdersList = action?.payload?.data;
    });
    builder.addCase(viewProcessOrder.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    });

    builder.addCase(printOrderInfo.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(printOrderInfo.fulfilled, (state, action) => {
      state.loading = false;
      state.orderInfo = action?.payload?.data;
    });
    builder.addCase(printOrderInfo.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    });
    builder.addCase(trackCustomerOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(trackCustomerOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.orderId = action?.payload?.data?.id;
    });
    builder.addCase(trackCustomerOrder.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(searchOrder.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(searchOrder.fulfilled, (state, action) => {
      state.loading = false;
      state.ordersList = action?.payload?.data;
    });
    builder.addCase(searchOrder.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(resendMail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(resendMail.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(resendMail.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(uploadVisaDocument.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(uploadVisaDocument.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(uploadVisaDocument.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(sendRefundMail.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(sendRefundMail.fulfilled, (state, action) => {
      state.loading = false;
    });
    builder.addCase(sendRefundMail.rejected, (state, action) => {
      state.loading = false;
    });
    builder.addCase(getTwilioLog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getTwilioLog.fulfilled, (state, action) => {
      state.loading = false;
      state.twilioLogList = action?.payload?.data?.rows;
      state.twilioLogCount = action?.payload?.data?.count;
    });
    builder.addCase(getTwilioLog.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    });
    builder.addCase(getProcessingLog.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getProcessingLog.fulfilled, (state, action) => {
      state.loading = false;
      state.processingLogList = action?.payload?.data?.rows;
      state.processingLogCount = action?.payload?.data?.count;
    });
    builder.addCase(getProcessingLog.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    });
    builder.addCase(encryptOpenSSL.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(encryptOpenSSL.fulfilled, (state, action) => {
      state.loading = false;
      state.encryptedId = action?.payload?.encryptedId;
    });
    builder.addCase(encryptOpenSSL.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    });
    builder.addCase(getDefencePackPdf.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDefencePackPdf.fulfilled, (state, action) => {
      state.loading = false;
      state.encryptedId = action?.payload?.encryptedId;
    });
    builder.addCase(getDefencePackPdf.rejected, (state, action) => {
      state.loading = false;
      state.error = action?.payload;
    });
  },
});

export const { toggleSidebar, handleChange, handlePerRowsChange, handlePageChange, cleanOrdersList, toggleNavbar } = orderSlice.actions;   // exporting actions

export default orderSlice.reducer;

