import { configureStore } from "@reduxjs/toolkit";
import authReducer from "../features/user/authSlice";
import orderReducer from "../features/order/orderSlice";
import manageTeamReducer from "../features/manageteam/manageTeamSlice";
import gateWayReducer from "../features/paymentgateway/gateWaySlice";
import remarkReducer from "../features/remark/remarkSlice";
import visitorReducer from "../features/visitor/visitorSlice";

export default configureStore({
  reducer: {
    auth: authReducer,
    vistor: visitorReducer,
    order: orderReducer,
    manageTeam: manageTeamReducer,
    gateway: gateWayReducer,
    remark: remarkReducer,
  },
});

