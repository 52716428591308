import React, { useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Login from "../pages/AdminLogin/Login";

const LoginLayout = (props) => {
    useEffect( () => {
        console.log("inside login component");
    });
    return (
        <>
            <Routes>
                <Route path="/" element={<Login />} />
            </Routes>
        </>
    )
};

export default LoginLayout;