import styled from "styled-components";

export const StyledSidebar = styled.div`
  .sidebar {
    position: fixed;
    left: 0;
    top: 90px;
    overflow: auto;
    background: #04087D;
    width: 300px;
    height: 100%;

    @media only screen and (max-width: 991px) {
      position: fixed;
      left: 0;
      top: 0;
      width: 280px;
      height: 100%;
      z-index: 9999;
      padding: 108px 0px 20px;
      opacity: 0;
      visibility: hidden;
      overflow: hidden;
      transition: all 0.5s ease-out 0s;
      .open {
        opacity: 1;
        visibility: visible;
        overflow: auto;
      }
    }
    // @media only screen and (max-width: 767px) {
    //   padding-top: 80px;
    // }
  }

  .open {
    @media only screen and (max-width: 991px) {
      opacity: 1;
      visibility: visible;
      overflow: auto;
    }
  }
`;

export const StyledAdminMenu = styled.div`
  & ul {
    margin: 0;
    padding: 30px 0px;

    @media only screen and (max-width: 991px) {
      margin: 0;
      padding: 0;
    }
  }

  .active {
    background-color: #cf0a21;
    .count {
      background: #fff;
      color: #111;
    }
  }

  & ul li {
    padding: 0px 20px;
  }
  & ul li a {
    display: flex;
    align-items: flex-start;
    padding: 15px 0px;
    line-height: normal;
    color: #fff;
    font-size: 16px;

    @media only screen and (max-width: 767px) {
      padding: 10px 0px;
      font-size: 14px;
    }
  }
  & ul li:last-child NavLink {
    border-bottom: 0px;
  }

  & a {
    text-decoration: none;
  }

  & ul li .side-icon {
    display: inline-block;
    margin-right: 15px;
    vertical-align: middle;
    line-height: normal;
    width: 20px;
    float: left;
  }
  .count {
    background: #1188ff;
    padding: 5px 1px;
    border-radius: 5px;
    color: #fff;
    font-size: 13px;
    min-width: 30px;
    text-align: center;
    display: inline-block;
    margin-left: auto;
  }
  & ul li.active .count {
    background: #fff;
    color: #111;
  }
  .close-menu {
    display: none;

    @media only screen and (max-width: 991px) {
      display: block;
      position: absolute;
      right: 20px;
      top: 20px;
      width: 45px;
      height: 45px;
      border-radius: 100%;
      background: none;
      border: 1px solid #fff;
      color: #fff;
      font-size: 22px;
      padding: 0;
    }
  }
`;
