import React from 'react';
import { Link } from "react-router-dom";
import { EmailContainer, StyleLogo } from "./style";
import { useSelector } from "react-redux";
import { encryptVal } from "../../../utils/utility";
import AdminLogo from "../../../assets/images/logo-cambodia.jpg";

const EmailContentSection = () => {

  const OrderDetails = useSelector((state) => state?.order?.orderDetails);
  return (
    <EmailContainer>
      <StyleLogo>
        <img
          src={AdminLogo}
          alt="AdminLogo"
          styel={{ padding: " -37px", width: "130px" }}
        />
      </StyleLogo>
      <hr />
      <p>
        <b>Dear Customer: {(typeof OrderDetails != "undefined" && OrderDetails.length > 0 && OrderDetails[0]?.given_name) ? `${OrderDetails[0]?.given_name} ${OrderDetails[0]?.surname}` : ""}</b>
      </p>
      <p>We would like to inform you that your eVisa application has been successfully approved.</p>

      <p>
        Please download your eVisa document from the link below.
      </p>
      <p>Username :  <Link to="inquiries@cambodia-portal.online">{typeof OrderDetails != "undefined" && OrderDetails.length > 0 && OrderDetails[0]?.email ? OrderDetails[0]?.email : ""} </Link></p>
      <p className="flex-class">
        <b>Order ID: </b>{typeof OrderDetails != "undefined" && OrderDetails.length > 0 && OrderDetails[0]?.order_id ? OrderDetails[0]?.order_id : ""}
      </p>

      <Link to={`https://admin-cb.online/track-order/${typeof OrderDetails != "undefined" && OrderDetails.length > 0 && OrderDetails[0]?.order_id ? encryptVal(OrderDetails[0]?.order_id) : ""}`} target="_black" className="apply-btn">
        Click Here
      </Link>
      <br />
      <br />

      <p>The Cambodia eVisa document is valid for 90 days from the date that your visa was issued. You can stay in Cambodia for 30 days in total.</p>
      <p>Please make sure you check the official requirements and health regulations before travelling to your destination.</p>
      <p>We remain at your disposal for any further questions or assistance you may need regarding your Cambodia eVisa.</p>

      {/* <p>
        Should you have any inquiries, please contact us through our
        <Link to="https://cambodia-portal.online/contact_us.php"> contact form </Link>or via email at {" "}
        <Link to="inquiries@cambodia-portal.online">inquiries@cambodia-portal.online  </Link>
        indicating your order ID.
      </p> */}

      <p className="flex-class">
        <b>Customer Service Number:</b> +1 (407) 809 - 3155
      </p>
      <p className="flex-class">
        <b>Order ID:</b>{typeof OrderDetails != "undefined" && OrderDetails.length > 0 && OrderDetails[0]?.order_id ? OrderDetails[0]?.order_id : ""}
      </p>
      <p>
        <b>Important Notice:</b>
      </p>
      <p>
        {" "}
        If you are not satisfied with the service you have received or wish to file a complaint, please contact us at <Link to="mailto:inquiries@cambodia-portal.online">inquiries@cambodia-portal.online </Link> ,
        where we will be happy to assist you or alternatively click on one of the links below for more information.
      </p>
      <p>You can access the terms and conditions you agreed to by clicking here <Link to="https://cambodia-portal.online/terms.php">click here</Link></p>
      <p>Thank you for using our services.</p>
      <p> Regards,</p>
      <p><b>Processing Department</b></p>
      <p>
        <Link to="https://cambodia-portal.online/terms.php">Terms</Link> |{" "}
        <Link to="https://cambodia-portal.online/privacy.php">Privacy</Link> |{" "}
        <Link to="https://cambodia-portal.online/contact.php">Contact</Link> |{" "}
        <Link to="https://cambodia-portal.online/refund.php">Refund</Link>
      </p>
    </EmailContainer>
  );
}

export default EmailContentSection